export const CLEARANCE = {
  SUPER_ADMIN: 10,
  BRANCH_ADMIN: 4,
  FINANCE: 3,
  TECHNICIAN: 2,
  BLOCKED: 0
};

export const CLEARANCE_NAME = {
  10: 'Super Admin',
  4: 'Branch Admin',
  3: 'Finance',
  2: 'Technician',
  0: 'Blocked'
};

export const STATUS_CODE = {
  QUEUE: 10,
  ONGOING: 20,
  FINISH: 30,
  OUT: 35
};

export const INVOICE_STATUS_NAME = {
  [STATUS_CODE.QUEUE]: 'Queue',
  [STATUS_CODE.ONGOING]: 'Ongoing',
  [STATUS_CODE.FINISH]: 'Finish',
  [STATUS_CODE.OUT]: 'Out'
};

export const STATUS_COLOR = {
  [STATUS_CODE.QUEUE]: 'primary',
  [STATUS_CODE.ONGOING]: 'warning',
  [STATUS_CODE.FINISH]: 'success',
  [STATUS_CODE.OUT]: 'grey'
};

export const REPORT_CHART_OPTIONS = {
  chart: {
    toolbar: { show: false }
  },
  stroke: {
    curve: 'smooth',
    dashArray: [0, 8],
    width: [4, 2]
  },
  grid: {
    borderColor: '#e7e7e7'
  },
  legend: {
    show: false
  },
  colors: ['#9188F3', '#b8c2cc'],
  // fill: {
  //   type: 'gradient',
  //   gradient: {
  //     shade: 'dark',
  //     inverseColors: false,
  //     gradientToColors: ['#7367F0', '#b8c2cc'],
  //     shadeIntensity: 1,
  //     type: 'horizontal',
  //     opacityFrom: 1,
  //     opacityTo: 1,
  //     stops: [0, 100, 100, 100]
  //   }
  // },
  markers: {
    size: 0,
    hover: {
      size: 5
    }
  },
  xaxis: {
    labels: {
      style: {
        cssClass: 'text-grey fill-current'
      }
    },
    axisTicks: {
      show: true
    },
    categories: [],
    axisBorder: {
      show: false
    }
  },
  yaxis: {
    tickAmount: 5,
    labels: {
      style: {
        cssClass: 'text-grey fill-current'
      },
      formatter(val) {
        return val > 999 ? `${(val / 1000).toFixed(1)}k` : val;
      }
    }
  },
  tooltip: {
    x: { show: false }
  }
};

export default {
  CLEARANCE
};
