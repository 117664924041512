import moment from 'moment';
import router from '@/plugins/router'; // eslint-disable-line
import AuthRepo from '@/repositories/auth_repo';

const states = {
  token: null,
  refreshToken: null,
  expiresAt: null,
  name: null,
  branch: null,
  clearance: null
};

const getters = {
  getToken(state) {
    return state.token;
  },
  getRefreshToken(state) {
    return state.refreshToken;
  },
  getExpiresAt(state) {
    return state.expiresAt;
  },
  getName(state) {
    return state.name;
  },
  getBranch(state) {
    return state.branch;
  },
  getClearance(state) {
    return state.clearance;
  }
};

const mutations = {
  setToken(state, {
    token, refreshToken, expiresAt, name, branch, clearance
  }) {
    if (token) state.token = token;
    if (refreshToken) state.refreshToken = refreshToken;
    if (expiresAt) state.expiresAt = expiresAt;
    if (name) state.name = name;
    if (branch) state.branch = branch;
    if (clearance) state.clearance = clearance;
  },
  flushToken(state) {
    state.token = null;
    state.refreshToken = null;
    state.expiresAt = null;
    state.clearance = null;
    state.name = null;
    state.branch = null;
  }
};

const actions = {
  login({ commit, dispatch }, data) {
    const {
      token, refresh_token: refreshToken, expires_in: expiresIn, name, branch, clearance
    } = data;

    const now = moment().format();
    const expiresAt = moment().add(expiresIn, 'seconds').format();

    commit('setToken', {
      token, refreshToken, expiresAt, name, branch, clearance
    });

    // flush token and redirect
    const diff = moment(expiresAt).diff(moment(now), 'seconds') * 1000;
    dispatch('authTimeout', diff);
    router.replace('/home');
  },
  logout({ commit }) {
    this._vm.$vs.notify({
      title: 'Session End', text: 'logging out now', color: 'danger', position: 'top-center', time: 1000
    });

    setTimeout(() => {
      commit('flushToken');
      router.replace('/login');
    }, 1000);
  },
  async relogin({ getters: get, dispatch, commit }) {
    console.log(`REFERSHING TOKEN: ${new Date().toISOString()}`); // eslint-disable-line

    const refreshToken = get.getRefreshToken;
    if (refreshToken) {
      await AuthRepo.refresh({ refresh_token: refreshToken })
        .then((result) => {
          const { data: { token, expires_in: expiresIn } } = result;

          const now = moment().format();
          const expiresAt = moment().add(expiresIn, 'seconds').format();
          const diff = moment(expiresAt).diff(moment(now), 'seconds') * 1000;

          commit('setToken', { token, expiresAt });
          dispatch('authTimeout', diff);
        })
        .catch((err) => {
          console.error(`ERRROR: ${err.message}`); // eslint-disable-line
          dispatch('logout');
        });
    } else {
      dispatch('logout');
    }
    return Promise.resolve();
  },
  authTimeout({ dispatch }, diff) {
    setTimeout(() => { dispatch('relogin'); }, diff);
  }
};

export default {
  state: states,
  getters,
  mutations,
  actions
};
