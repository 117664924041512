import Vue from 'vue';
import Router from 'vue-router';
import store from '@/plugins/store';
import NotFound from '@/views/errors/404.vue';

import { CLEARANCE } from '@/utils/constants';
import BaseView from '@/views/Base.vue';

Vue.use(Router);

const HOME = '/home';

const Authenticated = (clearance = null) => (to, from, next) => {
  if (!store.getters.getToken) {
    return next('/login');
  }

  if (clearance) {
    const priv = store.getters.getClearance;
    if (clearance instanceof Array) {
      if (!clearance.includes(priv)) {
        return next(HOME);
      }
    } else if (priv !== clearance) {
      return next(HOME);
    }
  }

  return next();
};

const NotAuthenticated = (to, from, next) => {
  if (store.getters.getToken) return next(HOME);
  return next();
};

const router = new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  scrollBehavior () {
    return { x: 0, y: 0 };
  },
  routes: [
    {
      path: '',
      component: () => import('@/layouts/main/Main.vue'),
      children: [
        {
          path: '/',
          redirect: '/home'
        },
        {
          path: '/home',
          name: 'home',
          component: () => import('@/views/Home.vue'),
          beforeEnter: Authenticated([CLEARANCE.SUPER_ADMIN, CLEARANCE.BRANCH_ADMIN, CLEARANCE.FINANCE])
        },
        {
          path: '/invoices',
          component: BaseView,
          children: [
            {
              path: '/',
              component: () => import('@/views/invoices/InvoiceList.vue'),
              beforeEnter: Authenticated([CLEARANCE.SUPER_ADMIN, CLEARANCE.FINANCE, CLEARANCE.BRANCH_ADMIN])
            },
            {
              path: 'create',
              component: () => import('@/views/invoices/InvoiceCreate.vue'),
              beforeEnter: Authenticated([CLEARANCE.FINANCE, CLEARANCE.BRANCH_ADMIN])
            },
            {
              path: ':id',
              component: () => import('@/views/invoices/InvoiceDetail.vue'),
              beforeEnter: Authenticated([CLEARANCE.SUPER_ADMIN, CLEARANCE.FINANCE, CLEARANCE.BRANCH_ADMIN])
            }
          ]
        },
        {
          path: '/customers',
          component: BaseView,
          children: [
            {
              path: '/',
              component: () => import('@/views/customers/CustomerList.vue'),
              beforeEnter: Authenticated([CLEARANCE.SUPER_ADMIN, CLEARANCE.BRANCH_ADMIN, CLEARANCE.FINANCE])
            },
            {
              path: ':id',
              component: () => import('@/views/customers/CustomerDetail.vue'),
              beforeEnter: Authenticated([CLEARANCE.SUPER_ADMIN, CLEARANCE.BRANCH_ADMIN, CLEARANCE.FINANCE])
            }
          ]
        },
        {
          path: '/tickets',
          component: BaseView,
          children: [
            {
              path: '/',
              component: () => import('@/views/tickets/TicketList.vue'),
              beforeEnter: Authenticated([CLEARANCE.SUPER_ADMIN, CLEARANCE.BRANCH_ADMIN, CLEARANCE.FINANCE])
            }
          ]
        },
        {
          path: '/technicians',
          component: BaseView,
          children: [
            {
              path: '/',
              component: () => import('@/views/technicians/TechnicianList.vue'),
              beforeEnter: Authenticated([CLEARANCE.SUPER_ADMIN, CLEARANCE.BRANCH_ADMIN])
            }
          ]
        },
        {
          path: '/branches',
          component: BaseView,
          children: [
            {
              path: '/',
              component: () => import('@/views/branches/BranchList.vue'),
              beforeEnter: Authenticated(CLEARANCE.SUPER_ADMIN)
            },
            {
              path: ':id',
              component: () => import('@/views/branches/BranchDetail.vue'),
              beforeEnter: Authenticated(CLEARANCE.SUPER_ADMIN)
            }
          ]
        }
      ]
    },
    {
      path: '/login',
      name: 'login',
      component: () => import('@/views/Login.vue'),
      beforeEnter: NotAuthenticated
    },
    {
      path: '*',
      component: NotFound
    }
  ]
});

router.afterEach(() => {
  // Remove initial loading
  const appLoading = document.getElementById('loading-bg');
  if (appLoading) {
    appLoading.style.display = 'none';
  }
});

export default router;
