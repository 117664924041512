import Vue from 'vue';
import Vuex from 'vuex';
import createPersistedState from 'vuex-persistedstate';

import auth from './modules/auth';
import base from './modules/base';

Vue.use(Vuex);

export default new Vuex.Store({
  plugins: [
    createPersistedState()
  ],
  modules: {
    base,
    auth
  },
  strict: process.env.NODE_ENV !== 'production'
});
