import axios from 'axios';
import store from '@/plugins/store';

axios.defaults.headers.common['Content-Type'] = 'application/json';
axios.defaults.baseURL = process.env.VUE_APP_BASE_URI;

axios.interceptors.request.use((config) => {
  const jwtToken = store.getters.getToken;
  if (jwtToken) {
    config.headers.Authorization = `Bearer ${jwtToken}`;
  } else {
    config.headers.Authorization = `Basic ${process.env.VUE_APP_API_SECRET}`;
  }
  return config;
});

axios.interceptors.response.use(
  response => response,
  error => Promise.reject(error)
  // const { response } = error;
  // if ((response.status === 401 || response.status === 403) && store.getters.getToken) {
  //   setTimeout(() => {
  //     store.dispatch('relogin');
  //   }, 750);
  // }

);

export default axios;
