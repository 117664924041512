import Vue from 'vue';
import VueAxios from 'vue-axios';
import Vuesax from 'vuesax';
import { VueHammer } from 'vue2-hammer';
import VeeValidate from 'vee-validate';

import App from './App.vue';
import router from './plugins/router';
import store from './plugins/store';
import http from './plugins/http';


import 'material-icons/iconfont/material-icons.css'; // Material Icons
import 'vuesax/dist/vuesax.css';
import '../themeConfig.js';
import './plugins/global_components';
import './assets/scss/main.scss';
import '@/assets/css/main.css';
import 'prismjs';
import 'prismjs/themes/prism-tomorrow.css';
import './registerServiceWorker';

Vue.use(Vuesax);
Vue.use(VueAxios, http);
Vue.use(VueHammer);
Vue.use(VeeValidate);

require('./assets/css/iconfont.css');

Vue.config.productionTip = false;

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app');
