<template>
  <blank>
    <div class="h-screen flex w-full bg-img">
      <div class="vx-col flex items-center justify-center flex-col sm:w-1/2 md:w-3/5 lg:w-3/4 xl:w-3/5 mx-auto text-center">
        <img src="../../assets/images/pages/404.png" alt="graphic-404" class="mx-auto mb-4 responsive">
        <h1 class="mb-12 text-5xl">
          404 - Page Not Found!
        </h1>
        <p class="mb-16">
          paraphonic unassessable foramination Caulopteris worral Spirophyton encrimson esparcet aggerate chondrule restate whistler shallopy biosystematy area bertram plotting unstarting quarterstaff.
        </p>
        <vs-button size="large" to="/">
          Back to Home
        </vs-button>
      </div>
    </div>
  </blank>
</template>

<script>
import Blank from '@/layouts/blank/Blank.vue';

export default {
  data() {
    return {
      value1: ''
    };
  },
  components: {
    Blank
  }
};
</script>
